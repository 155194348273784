.material-box-shadow {
  border: none !important;
  border-radius: 0 !important;
}

.material-box-shadow::before {
  box-shadow: 0px 1px 35px 0px rgba(57, 116, 183, 0.6);
  mix-blend-mode: multiply;
  content: " ";

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.material-box-light-shadow {
  border: none !important;
  border-radius: 0 !important;
}

.material-box-light-shadow::before {
  box-shadow: 0px 1px 35px 0px rgba(57, 116, 183, 0.3);
  mix-blend-mode: multiply;
  content: " ";

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.sprite-single {
  width: 45px;
}
