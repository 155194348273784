@import "./assets/styles/variables.scss";

/* Start Main Rules */
html {
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100vh;
  position: relative;
}

body {
  font-family: $defaultFontFamily;
  overflow-x: hidden;
}

main {
  flex: 1;
}

.sw-app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.h-100 {
  min-height: calc(100vh - 68px);
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

img {
  max-width: 100%;
}

.text-white {
  a {
    color: #ffffff !important;
  }
}

/* End Main Rules */

/* Start Responsive iframes */
.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/* End Responsive iframes */

/* Start Common Forms */
.sw-form {
  input,
  select {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(19, 120, 162, 0.15);
  }

  select.select-placeholder {
    color: #6c757d;
  }

  input:focus,
  select:focus {
    background-color: #ffffff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 10px rgba(19, 120, 162, 0.15);
  }

  textarea {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(19, 120, 162, 0.15);
    background-color: rgba(255, 255, 255, 0.85);
  }

  textarea:focus {
    background-color: #ffffff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 10px rgba(19, 120, 162, 0.15);
  }

  .invalid-field {
    border-color: rgba(212, 1, 1, 0.55);
  }
}

.notifications-tr {
  width: 520px !important;
  max-width: 100%;
}
/* End Common Forms */

/* Start Pretty Scrollbar */
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-thumb {
  background: #6060d6;
}
body::-webkit-scrollbar-track {
  background: #f7f7f7;
}
/* End Pretty Scrollbar */
