@import "./variables.scss";

.error-text {
  color: $swErrorColor;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.content-section {
  h1 {
    font-family: $headingFontFamily;
    color: lighten($headingFontColor, 10%);
    font-weight: 400;
    //letter-spacing: 2px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

    @media (max-width: 530px) {
      font-size: 24px;
    }
  }

  h2 {
    font-family: $headingFontFamily;
    color: lighten($headingFontColor, 10%);
    font-weight: 400;
    //letter-spacing: 2px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

    @media (max-width: 530px) {
      font-size: 22px;
    }
  }

  h3 {
    font-family: $headingFontFamily;
    color: lighten($headingFontColor, 10%);
    //letter-spacing: 3px;
    margin: 40px 0 40px 0;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

    @media (max-width: 530px) {
      font-size: 20px;
    }
  }

  h4,
  h5,
  h6 {
    font-family: $headingFontFamily;
    color: lighten($headingFontColor, 10%);
    //letter-spacing: 3px;

    @media (max-width: 530px) {
      font-size: 16px;
    }
  }

  p {
    color: $paragraphColor;
  }

  li {
    color: $paragraphColor;
  }

  ul.two-col {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    @media only screen and (max-width: 500px) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
  }

  label {
    // color: $labelColor;
    font-weight: 500 !important;
    //letter-spacing: 0.5px;
  }

  // input[type="text"],
  // textarea,
  // #react-select-2-input {
  //   background-color: #f5f5f7 !important;
  // }

  .p-details {
    line-height: 1.65em;
  }
}

.stand-alone-link {
  font-size: 14px;
  color: darken($swPrimaryColor, 20%);
  padding-bottom: 0.05em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $swPrimaryColor;
  text-decoration: none;
  transition: all 0.5s;

  &:hover {
    color: darken($swPrimaryColor, 10%);
  }
}

.primary-text {
  font-weight: bold;
  color: ($swPrimaryColor,) !important;
  letter-spacing: 1px;
}
