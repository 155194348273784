@import "./variables.scss";

.dialog-primary {
  background-color: $swPrimaryColor;
  border: 1px solid $swPrimaryColor;

  &:hover {
    color: $swPrimaryColor;
    background-color: white;
    border: 1px solid $swPrimaryColor;
  }
}

.dialog-secondary {
  background-color: white;
  border: 1px solid $swPrimaryColor;
  color: $swPrimaryColor;

  &:hover {
    background-color: $swPrimaryColor;
    color: white;
    border: 1px solid white;
  }
}
