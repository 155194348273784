@import "./variables.scss";

@mixin buttonStyle(
  $color,
  $font-weight,
  $bg-color,
  $hover-bg-color,
  $padding,
  $hover-color
) {
  color: $color !important;
  font-weight: 600;
  //letter-spacing: 2px;
  background-color: $bg-color !important;
  padding: $padding !important;
  transition: 0.2s all;
  text-transform: uppercase;
  border: unset;
  font-size: 18px;
  border-radius: 4;

  &:hover {
    background-color: $hover-bg-color !important;
    color: $hover-color !important;
    border: unset;
  }
}

.sw-btn-primary {
  @include buttonStyle(
    #fff,
    500,
    $swBtnPrimaryColor,
    darken($swBtnPrimaryColor, 10%),
    5px 20px,
    #fff
  );
}

.sw-btn-secondary {
  @include buttonStyle(
    lighten(black, 30%),
    500,
    $swSecondaryColor,
    darken($swSecondaryColor, 10%),
    10px 50px,
    #fff
  );
}

.sw-btn-section-feature {
  @include buttonStyle(
    #fff,
    500,
    $swSectionFeatureColor,
    darken($swSectionFeatureColor, 10%),
    10px 50px,
    #fff
  );
}

.sw-btn-contact {
  @include buttonStyle(
    #fff,
    500,
    $swContactColor,
    darken($swContactColor, 10%),
    5px 20px,
    #fff
  );

  //letter-spacing: 4px;
  display: inline-block;
  // height: 46px;
  font-size: 12px;
  line-height: 27px;
  margin-left: 8px;
}
.sw-btn-section-feature:hover {
  text-decoration: none !important;
}
