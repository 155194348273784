@mixin responsive-table {
  .empty {
    .icon-style {
      font-size: 3em;
      color: #3974b8;
    }

    .text {
      text-align: center;
    }
  }

  @media only screen and (max-width: 768px) {
    .rt-table {
      flex: none !important;
    }
    .rt-thead,
    .rt-tbody {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      flex: none !important;
      box-sizing: border-box !important;
    }
    .rt-tr,
    .rt-th,
    .rt-td {
      display: block !important;
      flex: none !important;
      width: 100% !important;
      max-width: 100% !important;
      box-sizing: border-box !important;
      padding: 5px;
      overflow: hidden;
    }
  }
}

.responsive-table {
  @include responsive-table;
}
